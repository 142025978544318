const ESP_CUSTOM = {
       "header.home": "Inicio",
       "header.incentives": "Incentivos",
       "header.electricVehicles": "Vehículos Eléctricos",
       "header.electricians": "Electricistas",
       "header.homeChargers": "Cargadores domésticos",
       "header.rates": "Calculadora de ahorros",
       "header.promise.title": "Saque el máximo provecho a su VE.",
       "header.promise.subtitle": "Calcule y compare los costos, ahorros, incentivos y más de los VE.",
       "header.promise.cta": "Inicie aquí con incentivos",

       "header.cards.incentives": "Buscar incentivos",
       "header.cards.electricians": "Encuentre un electricista certificado",
       "header.cards.home-chargers": "Compre cargadores domésticos",
       "header.cards.rates": "Ver la calculadora de ahorros",

       "homeChargers.title": "Cargadores domésticos",
       "homeChargers.subtitle": "Encuentra opciones de cargadores VE nivel 2 adecuados para tu hogar y VE. Ajusta los filtros y personaliza tus resultados.",
       "homechargerEvfilter": "Filtros Básicos",
       "homechargerChargerType": "TIPO DE CARGADOR",
       "homeChargers.selectMake": "Marca",
       "homeChargers.selectModel": "Modelo",
       "homeChargers.disclaimer": "Fabricantes de cargadores, ¿desean que su cargador aparezca aquí? Póngase en contacto con {email}.",
       "homechargers.caption": "Los cargadores VE en la lista de esta pagina web no son manufacturados o avalados por SDG&E.",

       "compareVehicles.subTitle": "Seleccione al menos dos vehículos para comenzar la comparación.",

       "compareVehicles.fuelType": "Tipo de combustible",
       "compareVehicles.carMake": "Marca de auto",
       "compareVehicles.carModel": "Modelo de auto",

       "homepage.zipcode.title" : "¿Sabías que ofrecemos una variedad de planes de precios eléctricos? Encuentre el mejor plan de precios de vehículos eléctricos para usted",
       "homepage.zipcode.subtitle" : "Esta herramienta identificará claramente el plan de precios de vehículos eléctricos de menor costo.",
       "homepage.zipcode.getStarted" : "Empezar",
       "homepage.zipcode.disclaimer" : "Planes de precios personalizados en menos de 1 minuto. No es necesario registrarse.",

       "home.vehicleCarouselTitle": "Hay más vehículos eléctricos que nunca. Descubre el tuyo.",
       "home.vehicleCarouselSubTitle": "Compare su selección con un vehículo a gasolina similar. Un vehículo eléctrico puede ahorrarle dinero porque puede ser más económico de poseer y mantener que un automóvil 100% propulsado por gasolina.",
       "home.vehicleCarousel.browseAllEvs": "Explorar todos VEs",
       "home.vehicleCarousel.compareEvs": "Comparar VEs",

       "rateAdvisor.title": "Calculadora de Ahorros",
       "rateAdvisor.sub": "Identifica tus oportunidades de ahorro con un VE. Ajusta los filtros para ver tus ahorros.",
       "rateAdvisor.pricingPlanComparison": "Comparación de planes de precios",
       "rateComparisoin.youAreAlreadySaving": "Ya estás ahorrando hasta {highlightText} si tienes un EV y usa la {highlight} plan tarifario.",
       "rateComparison.youAreSaving": "Estás ahorrando hasta {highlightText} si tienes un EV y usa la {highlight} plan tarifario.",
       "rateComparison.youAreAlreadySavingVehicle": "Ya estás ahorrando hasta {highlightText} por año en {highlight} con un {selectedVehicle}.",
       "rateComparison.youAreSavingVehicle": "Puede ahorrar {highlightText} por año en {highlight} con un {selectedVehicle}.",
       "basicFilters": "Filtros Básicos",
       "currentPricingPlan": "PLAN DE PRECIOS ACTUAL",
       "prefferedEvMake": "MARCA DE VE PREFERIDA",
       "prefferedEvMake_tooltip": "Nota: Suponemos que actualmente conduce un automóvil de gasolina de tipo, marca y precio similar al del vehículo eléctrico elegido.",
       "milesDrivenAnnually_tooltip": "Según las estimaciones del Departamento de Transporte sobre el promedio de millas anuales recorridas en 2020, un conductor típico registra alrededor de 16,000 millas por año.",
       "prefferedEvChargingTimeframe_tooltip" : "Cargar por la noche (de 12 a 6 a.m.) es más rentable. Puedes programar tu vehículo eléctrico para que se cargue durante el horario que más te convenga.",
       "averageMonthlyCharge_tool" : "Seleccione el monto total de los cargos eléctricos (sin incluir los cargos de gas) en una factura mensual típica, independientemente de la temporada. Usamos esta cantidad para estimar una base de referencia para su consumo de energía actual.",
       "ev.electricPortionTooltip": "Seleccione el monto total de los cargos eléctricos (sin incluir los cargos de gas) en una factura mensual típica, independientemente de la temporada. Usamos esta cantidad para estimar una base de referencia para su consumo de energía actual.",
       "gasolinePriceInCentsPerGal_tooltip": "Ingrese el precio local de la gasolina. Nuestro valor predeterminado se basa en el precio de gasolina más reciente de su región, actualizado mensualmente.",
       "conventionalVehicleEfficiencyTooltip": "Para calcular posibles ahorros, ingrese las millas por galón (MPG) del vehículo de gasolina que desea reemplazar con un EV totalmente eléctrico o híbrido enchufable.",
       "portionOfPublicChargingTooltip": "Si no puedes cargar en casa, tendrás que hacerlo en estaciones de carga públicas de pago. Calcule qué porcentaje del tiempo de carga de los vehículos eléctricos se realizaría en estaciones de carga públicas de pago en lugar de en casa.",
       "costOfPublicChargingTooltip": "Estimamos el costo de las estaciones de carga públicas pagas, pero puede editar este filtro si conoce el costo de carga local.",
       "vehicleType" : "Seleccione todos los tipos de carrocería de vehículo que le interesen.",
       "prefferedEvModel": "MODELO DE VE PREFERIDO",
       "prefferedEvChargingTimeframe": "CALENDARIO PREFERIDO DE RECARGA DE VE",
       "averageMonthlyCharge": "PROMEDIO MENSUAL DE CARGA",
       "electricPortion": "PARTE ELÉCTRICA",
       "gasolinePrice": "PRECIO DE LA GASOLINA",
       "conventionalVehicleEfficiency": "EFICIENCIA DE LOS VEHÍCULOS CONVENCIONALES (MPG)",
       "portionOfPublicCharging": "PARTE DEL COBRO PÚBLICO",
       "costOfPublicCharging": "COSTO DEL COBRO PÚBLICO",
       "vehicle.gasoline.price": "PRECIO DE LA GASOLINA",
       "vehicle.gasoline.metricPrice": "PRECIO DE LA GASOLINA",
       "rateAdvisor.caption": "Esta calculadora de ahorros se proporciona con fines ilustrativos. No es una promesa ni garantía de que sus costos de carga reales sean iguales a los costos que se muestran en la herramienta. SDG&E no garantiza la exactitud, integridad o utilidad de la información de costos estimados. SDG&E renuncia expresamente a la responsabilidad por los daños y perjuicios de cualquier naturaleza que surjan en relación con el uso de la herramienta Calculadora de Ahorros. Sus costos de carga reales variarán en función de una serie de factores. “SDG&E” se refiere a San Diego Gas & Electric Company. ©2022 SDG&E. Reservados todos los derechos.",
       "rateTOUDR1Paragraph1" : "El precio de TOU-DR1 se basa en la cantidad de electricidad que usa, así como la hora y el día de la semana en que la usa. Con tres períodos de precios, existe la oportunidad de ahorrar más en noches y fines de semana.",
       "rateTOUDR1Paragraph2" : "Este plan podría ser una buena opción si puede cambiar parte de su consumo de electricidad lejos de las horas pico de 4 a 9 p.m. y puede aprovechar la electricidad de menor precio durante las horas de menor actividad durante las noches y los fines de semana.",
       "DRParagraph1" : "El plan de precios estándar de DR se basa en la cantidad de electricidad que usa, no cuando la usa. Cuanta menos electricidad use, menos pagará, a cualquier hora, de día o de noche.",
       "DRParagraph2" : "Este plan puede ser una buena opción para los hogares de bajo uso y aquellos que prefieren ahorrar conservando sin pensar en la hora del día.",
       "rateDRSESParagraph1" : "DR-SES es el plan para clientes de energía solar y otras energías renovables. El precio se basa en la hora y el día de la semana, junto con la cantidad de electricidad que usa. Ganará créditos en la factura enviando su exceso de energía a la red de energía.",
       "rateDRSESParagraph2" : "Este plan podría ser una buena opción para usted si genera energía renovable, si puede cambiar sus actividades de uso de energía a antes de las 4 p.m. y después de las 9 p.m., y si puede aprovechar la electricidad de menor precio durante las horas de menor actividad.",
       "rateEVTOU5Paragraph1" : "El plan EV-TOU-5 es para que los clientes carguen vehículos eléctricos en casa. El precio se basa en la cantidad de electricidad que usa y cuándo, con tres períodos de precios. A cambio de una tarifa de servicio básico, ofrecemos nuestras tarifas más bajas para la carga nocturna de vehículos eléctricos en casa.",
       "rateEVTOU5Paragraph2" : "Este plan podría ser una buena opción si puede cambiar parte de su consumo de electricidad lejos de las horas pico de 4 a 9 p.m. y puede cargar regularmente un vehículo eléctrico durante el período súper fuera de pico a altas horas de la noche.",

       "footer.disclaimer": "{COMPANY_NAME}® es una filial de Sempra®. SDG&E® y San Diego Gas & Electric son marcas registradas de San Diego Gas & Electric Company.",
       "footer.copyright": "© 1998-2024 San Diego Gas & Electric Company. Las marcas comerciales aquí utilizadas son propiedad de sus respectivos dueños. Todos los derechos reservados.",

       "evs.incentivesScoreOptions": "Filtros de elegibilidad de incentivos",

       "evfilter.carTitle" : "Preferencia de VE",

       "electricians.title": "Electricistas Certificados",
       "electricians.subtitle": "Para buscar un electricista certificado en su área ingrese su código postal. Haga clic en cada uno de los resultados para ver la información y contacto.",

       "electricians.map.caption": "¿Es usted un electricista instalador de estaciones de subministro de combustible VE certificado? Por favor contacte {email} para ser considerado en nuestra base de datos de electricistas certificados. Los electricistas en la lista de esta página web no son avalados ni empleados por SDG&E.",

       "saved": "ahorro",
       
       "free": "Gratis",
       "low": "Bajo",
       "medium": "Medio",
       "high": "Alto",

       "zipCode": "CÓDIGO POSTAL",
       "updateZipcode": "Actualizar código postal",
       "chargingMap.updateZipcode": "Actualizar código postal",

       "modal1": "Cual es el promedio del costo de carga de su VE por mes?",
       "modal2": "Paso",
       "modal3": "de",
       "modal4": "Siguiente",
       "modal5": "Cual VE posees o cual quiere comprar/rentar?",
       "modal6": "Sugerencia: Puedes ajustar los filtros después del resultado.",
       "modal7": "Elija su EV actual o preferido para ver ahorros potenciales en diferentes planes de precios. La selección del vehículo afecta los ahorros potenciales en la factura eléctrica debido a las diferencias en el rendimiento eléctrico y la eficiencia entre los modelos.",
       "modal8": "Previo",
       "modal9": "¿En promedio, cuantas millas maneja su auto al año?",
       "modal10": "Un conductor maneja un promedio de 12,000 millas al año.",
       "modal11": "¿A qué hora del día prefiere cargar su VE?",
       "modal12": "Recomendación: Cargar tu VE durante las horas de la noche (entre 12-6 a.m.) es más económico. Puedes programar tu VE para que se recargue durante la noche o a la hora más adecuada para ti.",
       "modal13": "Todas horas",
       "modal14": "¿Cuál es tu plan de tarifa actual?",
       "modal15": "Consejo: La mayoría de los clientes tienen la tarifa {TOU-DR1}.",
       "modal16": "Nota: Para los clientes con planes de precios que no son VE (incluidos DR, TOU-DR1 y DR-SES), asumimos que actualmente conduce un vehículo de gasolina con un tipo, marca y precio similares al VE seleccionado en el Paso 2 y mostramos los ahorros al cambiar a este VE. Para los clientes en el plan de precios EV-TOU-5, asumimos que ya posee el VE seleccionado y mostramos sus ahorros actuales en relación con los planes de precios que no son VE.",
       "viewSavings": " VER AHORROS",

       "savingsGraphYAxis": "Costos promedio estimados por año",

       "monthWord"   : "Mes",
       
       "rateComparison.youAreAlreadySaving": "Ya estás ahorrando hasta {highlightText} si tienes un vehículo eléctrico y utilizas el plan tarifario {highlight}.",

       "electricity": "Costo de electricidad",

       "savingsGraphHomeLabel": "Uso Electrico de Hogar",
       "savingsGraphGasLabel": "Vehículo de gasolina",
       "savingsGraphEVLabel": "Vehículo eléctrico",

       "incentives.selectModel": "Seleccionar modelo",
       "incentives.selectTrim": "Seleccione Recortar",
       "trim": "Recortar",

       "certifiedElectrician": "Electricista Certificado",

       "savingsGraphDisclaimer": "* Supone que alimenta su hogar con este plan de precios y actualmente maneja un vehículo a gasolina con un tipo, marca y precio similar al VE que seleccionó.",

       "incentives.householdIncomeTooltip": "Combine los ingresos de todos los miembros del hogar. Se utiliza para determinar la elegibilidad para incentivos.",

       "household_size_tooltip": "Se utiliza para determinar la elegibilidad para incentivos. Incluya a todos los miembros de su hogar (cónyuge, dependientes, etc.). Pocos o ningún incentivo mencionan hogares de más de 8 miembros, así que seleccione 8 si el tamaño de su hogar es de 8 o más.",

       "filing_status_tooltip": "Se utiliza para determinar la elegibilidad para incentivos.",

       "trade_in_tooltip": "Es posible que sea necesario retirar un vehículo antiguo a gasolina para obtener ciertos incentivos.",

       "household_income_tooltip": "Combine los ingresos de todos los miembros del hogar. Se utiliza para determinar la elegibilidad para incentivos.",

       "incentiveSort.largest" : "Primero el más grande",
       "incentiveSort.aToZ": "Alfabético: de la A a la Z",
       "incentiveSort.zToA": "Alfabético: Z a A",

       "footerPhrase1" : "Tarifas y Reglamentos",
       "footerPhrase2" : "Solicitud de datos de energía",
       "footerPhrase3" : "Términos y condiciones",
       "footerPhrase4" : "Normas de Conducta de la FERC - Orden 717",
       "footerPhrase5" : "Proyectos mayores",
       "footerPhrase6" : "Servicio al Cliente",
       "footerPhrase7" : "Publicaciones de la CPUC",
       "footerPhrase8" : "Carreras",
       "footerPhrase9" : "Centro de privacidad",
       "footerPhrase10" : "Tarifas Eléctricas Totales",
       "footerPhrase11" : "Elección del cliente",

}

export default ESP_CUSTOM;