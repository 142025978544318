import GoogleAnalytics from "react-ga4";
import TagManager from 'react-gtm-module';

const trackers = [];

// Avoiding having trackers populate as a side effect of importing the module.
const hydrateTrackers = () => {
  if (trackers.length) {
    return;
  }

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "production":
      trackers.push({
        trackingId: process.env.REACT_APP_GA_CLIENT_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CLIENT_TRACKING_OPTIONS_NAME,
        },
      });
      trackers.push({
        trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_ZAPPYRIDE_TRACKING_OPTIONS_NAME,
        },
      });
      trackers.push({
        trackingId: process.env.REACT_APP_GA_CE_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CE_TRACKING_OPTIONS_NAME,
        },
      });
      break;

    default:
      trackers.push({
        trackingId: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_OPTIONS_NAME,
        },
      });
      trackers.push({
        trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_ZAPPYRIDE_TRACKING_OPTIONS_NAME,
        },
      });
      trackers.push({
        trackingId: process.env.REACT_APP_GA_CE_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CE_TRACKING_OPTIONS_NAME,
        },
      });
  }
};

hydrateTrackers();

const GaTracker = {
  initialize: () => {
    if (trackers.length) {
      GoogleAnalytics.initialize(trackers);
    }

    let tagManagerArgs = {};
    switch (process.env.REACT_APP_ENVIRONMENT) {
       case "production":
              tagManagerArgs = {
                     gtmId: process.env.REACT_APP_GA_CLIENT_TRACKING_GTM_ID,
              };
              TagManager.initialize(tagManagerArgs);
              break;
       default:
              tagManagerArgs = {
                     gtmId: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_GTM_ID,
              };
              TagManager.initialize(tagManagerArgs);
       }
  },

  getPageName: (page) => {
    const lastCharIndex = page.length - 1;

    return page.length > 1 && page.charAt(lastCharIndex) === "/"
      ? page.substring(0, lastCharIndex)
      : page;
  },

  // For testing purposes only.
  doHydrate: () => {
    hydrateTrackers();
    GaTracker.initialize();
  },

  trackPage: (page, options = {}) => {
    page = GaTracker.getPageName(page);

    if (trackers.length) {
      trackers.forEach((tracker) => {
        GoogleAnalytics.set(
          {
            page,
            ...options,
          },
          [tracker.gaOptions.name]
        );
        GoogleAnalytics.send({ hitType: "pageview", page: page });
      });
    }
  },
};

export default GaTracker;
