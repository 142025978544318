import React from "react";
import "./Footer.scss";
import smallLogo from "../../../../client_customizations/assets/images/sdge_logo.png";
import { FormattedMessage } from "react-intl";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";


import face from "../../../../client_customizations/assets/images/icons/icon-fb@2x.png";
import twitter from "../../../../client_customizations/assets/images/icons/icon-tw@2x.png";
import linkedin from "../../../../client_customizations/assets/images/icons/icon-li@2x.png";
import insta from "../../../../client_customizations/assets/images/icons/icon-ig@2x.png";
import youtube from "../../../../client_customizations/assets/images/icons/icon-yt@2x.png";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

const Footer = ({ language, changeLanguage }) => {
  return (
    <div className="Footer">
      <div className="container pt-5 pb-4">
        <div className="row centered">
          <div className="col-md-2 mb-3 itemList">
            <a href="https://www.sdge.com/rates-and-regulations">
              <p>
                     <FormattedMessage
                            id="footerPhrase1"
                            defaultMessage="Rates and Regulations"
                            description="footerPhrase1"
                     />
              </p>
            </a>

            <a href="https://energydata.sdge.com/">
              <p>
                     <FormattedMessage
                            id="footerPhrase2"
                            defaultMessage="Energy Data Request"
                            description="footerPhrase2"
                     />
              </p>
            </a>

            <a href="https://www.sdge.com/terms-and-conditions">
              <p><FormattedMessage
                            id="footerPhrase3"
                            defaultMessage="Terms & Conditions"
                            description="footerPhrase3"
                     /></p>
            </a>
          </div>

          <div className="col-md-2 mb-3 itemList">
            <a href="https://www.sdge.com/rates-and-regulations/ferc-standards-conduct-order-717">
              <p><FormattedMessage
                            id="footerPhrase4"
                            defaultMessage="FERC Standards of Conduct - Order 717"
                            description="footerPhrase4"
                     /></p>
            </a>

            <a href="https://www.sdge.com/major-projects">
              <p><FormattedMessage
                            id="footerPhrase5"
                            defaultMessage="Major Projects"
                            description="footerPhrase5"
                     /></p>
            </a>

            <a href="https://www.sdge.com/residential/customer-service/contact-us">
              <p><FormattedMessage
                            id="footerPhrase6"
                            defaultMessage="Customer Service"
                            description="footerPhrase6"
                     /></p>
            </a>
          </div>

          <div className="col-md-2 mb-3 itemList">
            <a href="https://www.sdge.com/rates-and-regulations/proceedings">
              <p><FormattedMessage
                            id="footerPhrase7"
                            defaultMessage="CPUC Postings"
                            description="footerPhrase7"
                     /></p>
            </a>

            <a href="https://www.sdge.com/careers">
              <p><FormattedMessage
                            id="footerPhrase8"
                            defaultMessage="Careers"
                            description="footerPhrase8"
                     /></p>
            </a>

            <a href="https://www.sdge.com/privacy">
              <p><FormattedMessage
                            id="footerPhrase9"
                            defaultMessage="Privacy Center"
                            description="footerPhrase9"
                     /></p>
            </a>
          </div>

          <div className="col-md-2 mb-3 itemList">
            <a href="https://www.sdge.com/total-electric-rates">
              <p><FormattedMessage
                            id="footerPhrase10"
                            defaultMessage="Total Electric Rates"
                            description="footerPhrase10"
                     /></p>
            </a>

            <a href="https://www.sdge.com/customer-choice">
              <p><FormattedMessage
                            id="footerPhrase11"
                            defaultMessage="Customer Choice"
                            description="footerPhrase11"
                     /></p>
            </a>
          </div>
        </div>
        <div>
          <div className="row centered">
            <a
              className="social"
              href="https://www.facebook.com/SanDiegoGasandElectric/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="SDGE Facebook" src={face} />
            </a>

            <a
              className="social"
              href="https://twitter.com/SDGE"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="SDGE Twitter" src={twitter} />
            </a>
            <a
              className="social"
              href="https://www.linkedin.com/company/san-diego-gas-&-electric"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="SDGE Linkedin" src={linkedin} />
            </a>

            <a
              className="social"
              href="https://www.instagram.com/sdge/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="SDGE Instagram" src={insta} />
            </a>
            <a
              className="social"
              href="https://www.youtube.com/user/sdgewebmaster"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="SDGE Youtube" src={youtube} />
            </a>
          </div>
        </div>

        {/* <div className="copyright">
          <span style={{ fontFamily: "Arial, Helvetica" }}>
            &copy;
            {new Date().getFullYear().toString()} Consumers energy
          </span>
        </div>
        <div className="copyright">
          <span style={{ fontFamily: "Arial, Helvetica" }}>
            {" "}
            &copy;
            {new Date().getFullYear().toString() + " "} ZappyRide (c).
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://zappyride.com/legal-notices"
            >
              Legal notices.
            </a>
          </span>
        </div> */}

        <div className="disclaimer">
          <p>
            <FormattedMessage
              id="footer.disclaimer"
              defaultMessage="{COMPANY_NAME}® is a subsidiary of Sempra®. SDG&E® and San Diego Gas & Electric are registered trademarks of San Diego Gas & Electric Company."
              description="Footer Disclaimer"
              values={{
                     COMPANY_NAME: process.env.REACT_APP_FULL_COMPANY_NAME
              }}
              />
          </p>
          <p>
          <FormattedMessage
              id="footer.copyright"
              defaultMessage="© 1998-2024 San Diego Gas & Electric Company. The trademarks used herein are the property of their respective owners. All rights reserved."
              description="Footer Copyright"
              values={{
                     COMPANY_NAME: process.env.REACT_APP_FULL_COMPANY_NAME
              }}
              />
          </p>
        </div>
        <div className="smallLogo">
          <img
            style={{ width: 130, height: 40 }}
            alt="SDGE logo"
            src={smallLogo}
          />
        </div>
        <div className="disclaimer_container">
              <DisclaimerComponent clientName="zappynobackground"/>
        </div>
      </div>
    </div>
  );
};

export default Footer;
